import React from "react";
import Heroblock from "./Heroblock";
import { Container, Heading, Button } from "@chakra-ui/react";
import { Link } from "gatsby";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
function ContactFooter({ title, subtitle, name, email, phone }) {
  return (
    <Heroblock
      bgColor="black"
      justifyContent={"center"}
      mediaOpacity={0.7}
      video={true}
      minH={"50vh"}
      maxH={{ lg: "70vh", md: "90vh" }}
      mediaSrc={"bbg3-1280web.mp4"}
    >
      <Container variant="layoutContainer" py={0} mb={0} px={10}>
        <MaskSlidInAnimation threshold={1} duration={0.6}>
          <Heading
            fontSize={{ base: "4xl", lg: "6xl" }}
            maxW="100%"
            fontWeight="normal"
            mb="4"
            mt={{ base: 8, lg: 2 }}
            letterSpacing="tighter"
            color="brand.lightgray"
          >
            {title}
          </Heading>
        </MaskSlidInAnimation>
        <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
          <Heading
            fontSize={{ base: "xl", lg: "2xl" }}
            maxW={{ base: "100%", lg: "52%" }}
            fontWeight="normal"
            mb="4"
            color="brand.lightgray"
          ></Heading>
          <Link href={`mailto:${email}`} isExternal={true}>
            <Button
              variant="solid"
              backgroundColor="brand.green"
              color="brand.darkgray"
            >
              Email {name}
            </Button>
          </Link>
          {/* {phone && (
						<Link href={`tel:${phone}`} isExternal={true}>
							<Button
								mt={8}
								variant="solid"
								backgroundColor="brand.green"
								color="brand.darkgray">
								{phone}
							</Button>
						</Link>
					)} */}
        </FadeInAnimation>
      </Container>
    </Heroblock>
  );
}

export default ContactFooter;
